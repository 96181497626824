import React from 'react';
import Layout from '../../../components/layout';
import Vacancy from '../../../components/shared-components/vacancy';

const SEOStrategist = () => {
  return (
    <Layout>
      <Vacancy
        division="Sales & Marketing"
        vacancy="SEO Strategist"
        workType="Full time"
        // location="Yerevan, Armenia"
        intro={<p>Our Marketing team is looking for an ambitious SEO Strategist to grow our organic traffic.</p>}
        jobResponsibilities={
          <>
            <p className="secondary-text mb-0">Working in the sales & marketing team to:</p>

            <ul>
              <li className="secondary-text">Perform technical and on-page SEO audits</li>
              <li className="secondary-text">
                Perform keyword and topic research to uncover new traffic opportunities
              </li>
              <li className="secondary-text">Collaborate with our content team to implement content strategy</li>
              <li className="secondary-text">Develop backlink strategies</li>
              <li className="secondary-text">Identify and prospect for backlinks</li>
              <li className="secondary-text">Find link opportunities by engaging with publishers and communities.</li>
            </ul>
          </>
        }
        qualifications={
          <ul>
            <li className="secondary-text"> 2+ years of work experience in SEO</li>
            <li className="secondary-text">
              Excellent written and verbal knowledge of English (Office communications and work are in English)
            </li>
            <li className="secondary-text">A passion for SEO, keeping up with the latest trends and best practices</li>
            <li className="secondary-text">
              Experience with content marketing, technical SEO, on-page optimization, digital PR, and link prospecting
            </li>
            <li className="secondary-text">Experience with: Ahrefs, Semrush, Google Analytics, etc.</li>
            <li className="secondary-text">
              Proven and measurable success (please share case studies of previous work results)
            </li>
            <li className="secondary-text">Able to organize, prioritize and manage multiple projects simultaneously</li>
            <li className="secondary-text">
              Self-starter, always looking for opportunities to improve, grow and share ideas.
            </li>
          </ul>
        }
      />
    </Layout>
  );
};

export default SEOStrategist;
